import styled from '@emotion/styled'

import { Row as DefaultRow, Col as DefaultCol } from '../../UI/Grid'

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: 0 16px;
  width: 100%;
`

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`
export const Title = styled.h2`
  font-size: 2rem;
  line-height: 1.4;
  text-align: center;
  padding-bottom: 64px;

  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

export const FeatureTitle = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
  padding: 16px 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.1rem;
  }
`
export const Text = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.grey};
  line-height: 2rem;
  text-align: center;
`

export const Col = styled(DefaultCol)`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: calc(100% / 3);
  padding: 8px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    :not(:first-of-type) {
      margin-top: 32px;
    }
  }
`

export const Row = styled(DefaultRow)``

export const Icon = styled.img`
  max-height: 48px;
  width: auto;
`

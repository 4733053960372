import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import HeroWithImage from '../components/shared/HeroWithImage'
import TopSection from '../components/AboutUsPage/TopSection'
import Features from '../components/AboutUsPage/Features'
import SecondaryFeature from '../components/AboutUsPage/SecondaryFeature'
import BeforePreFooter from '../components/shared/BeforePreFooter'
import i18n from '../../config/i18n'

const AboutUs = ({ data: { aboutus }, pageContext: { locale }, location }) => {
  const { data } = aboutus
  const {
    hero_title: heroTitle,
    hero_image: heroImage,
    final_section_title: title,
    final_section_description: description,
    final_section_link_text: linkText,
    final_section_link_url: url,
    final_section_image: image,
  } = data

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} banner={heroImage.url} title={i18n[locale].seo.aboutUs.title} />
      <HeroWithImage src={heroImage.url} title={heroTitle} />
      <TopSection data={data} />
      <Features data={data} />
      <SecondaryFeature data={data} />
      <BeforePreFooter title={title} description={description} linkText={linkText} url={url} image={image.url} />
    </>
  )
}

export default AboutUs

AboutUs.propTypes = {
  data: PropTypes.shape({
    aboutus: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query AboutUsQuery($locale: String!) {
    aboutus: prismicAbout(lang: { eq: $locale }) {
      data {
        hero_title
        hero_image {
          url
        }
        top_section_black_title
        top_section_orange_title
        top_section_text {
          html
        }
        features_title
        features {
          image {
            url
          }
          name
          description
        }
        secondary_feature_image_left {
          url
        }
        secondary_feature_image_right {
          url
        }
        secondary_feature_black_title
        secondary_feature_orange_title
        secondary_feature_text {
          html
        }
        final_section_title
        final_section_image {
          url
        }
        final_section_link_url
        final_section_link_text
        final_section_description
      }
    }
  }
`

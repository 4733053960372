import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const BeforePreFooter = ({ title, description, linkText, url, image }) => (
  <S.Container>
    <S.Wrapper image={image}>
      <S.Overlay />
      <S.InnerWrapper>
        <S.Title>{title}</S.Title>
        <S.Text>{description}</S.Text>
        <S.Link to={url}>{linkText}</S.Link>
      </S.InnerWrapper>
    </S.Wrapper>
  </S.Container>
)

BeforePreFooter.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}
export default BeforePreFooter

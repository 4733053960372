import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const TopSection = ({ data }) => {
  const {
    top_section_black_title: blackTitle,
    top_section_orange_title: orangeTitle,
    top_section_text: { html },
  } = data

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.Title>
          {blackTitle}
          <span> {orangeTitle}</span>?
        </S.Title>
        <S.Text dangerouslySetInnerHTML={{ __html: html }} />
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

TopSection.propTypes = {
  data: PropTypes.shape({}).isRequired,
}
export default TopSection

import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'

export const Container = styled.section`
  display: flex;
  justify-content: center;
  padding: 0 16px;
`
export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  position: relative;

  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 502px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 48px 16px;
    min-height: 360px;
  }
`

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 650px;
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  opacity: 0.17;
  background: ${({ theme }) => theme.colors.black};

  z-index: 1;
  pointer-events: none;
`
export const Title = styled.h2`
  font-size: 2.5rem;
  line-height: 1.4;

  font-weight: bold;
  color: ${({ theme }) => theme.colors.bg};
  text-align: center;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

export const Text = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.bg};
  line-height: 2rem;
  text-align: center;
  padding: 16px 0;
`
export const Link = styled(GatsbyLink)`
  color: ${({ theme }) => theme.colors.bg};
  transition: color 0.2s ease-in-out;
  :hover {
    color: ${({ theme }) => theme.colors.orange};
  }
`

import styled from '@emotion/styled'

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: 64px 16px;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 48px 16px;
  }
`

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`
export const Title = styled.h2`
  font-size: 2rem;
  line-height: 1.4;

  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  span {
    color: ${({ theme }) => theme.colors.orange};
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

export const Text = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2rem;

  padding: 32px 0;
`

import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const SecondaryFeature = ({ data }) => {
  const {
    secondary_feature_black_title: blackTitle,
    secondary_feature_orange_title: orangeTitle,
    secondary_feature_text: { html },
    secondary_feature_image_left: { url: urlLeft },
    secondary_feature_image_right: { url: urlRight },
  } = data

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.Row>
          <S.Col>
            <S.Image src={urlLeft} alt="Bengalesisnika" />
          </S.Col>
          <S.Col>
            <S.Image src={urlRight} alt="Bengalesisnika" />
          </S.Col>
        </S.Row>
        <S.Title>
          {blackTitle}
          <span> {orangeTitle}</span>
        </S.Title>
        <S.Text dangerouslySetInnerHTML={{ __html: html }} />
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

SecondaryFeature.propTypes = {
  data: PropTypes.shape({}).isRequired,
}
export default SecondaryFeature

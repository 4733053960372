import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const Features = ({ data }) => {
  const { features_title: title, features } = data
  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.Title>{title}</S.Title>
        <S.Row>
          {features.map(feature => {
            const {
              name,
              description,
              image: { url },
            } = feature
            return (
              <S.Col key={name}>
                <S.Icon src={url} alt={name} />
                <S.FeatureTitle>{name}</S.FeatureTitle>
                <S.Text>{description}</S.Text>
              </S.Col>
            )
          })}
        </S.Row>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

Features.propTypes = {
  data: PropTypes.shape({}).isRequired,
}
export default Features
